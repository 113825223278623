 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { BaseService, useServiceStore } from "@opendash/core";
import {
  changeLanguage,
  getCurrentLanguageSync,
  translate,
  translateSync,
} from "@opendash/i18n";

import Parse from "parse";

import {
  GlossaryEntry,
  Invoice,
  InvoicePage,
  Language,
  TenantConfig,
  TenantDomain,
  Video,
} from "./types";

import { DEFAULT_TENANT_ID } from "./const";
















export class AppService extends BaseService {
  __init() {this.error = false}
  __init2() {this.errorTenantNotFound = false}

  constructor() {
    super({
      initialState: {
        tenantId: null,
        tenantConfig: undefined,
        invoices: [],
        invoicePages: [],
        glossaryEntries: [],
        glossaryEntriesMapById: {},
        glossaryEntriesMapByKeyword: {},
        gloassryEntriesMapByTag: {},
        videos: [],
        frontpageImage: undefined,
        forceLanguage: undefined,
        embedded: false,
      },
    });AppService.prototype.__init.call(this);AppService.prototype.__init2.call(this);;
  }

   async init() {
    try {
      const tenantDomain = await new Parse.Query(TenantDomain)
        .equalTo("domain", _optionalChain([window, 'optionalAccess', _ => _.location, 'optionalAccess', _2 => _2.hostname]))
        .first();

      const tenant = _optionalChain([tenantDomain, 'optionalAccess', _3 => _3.get, 'call', _4 => _4("tenant")]);
      const tenantId = _optionalChain([tenant, 'optionalAccess', _5 => _5.id]);

      if (!tenant || !tenantId) {
        this.error = true;
        this.errorTenantNotFound = true;
        this.setLoading(false);

        return;
      }

      const tenantConfig = await new Parse.Query(TenantConfig)
        .equalTo("tenant", tenant)
        .first();

      if (
        _optionalChain([tenantConfig, 'optionalAccess', _6 => _6.get, 'call', _7 => _7("forceHost")]) &&
        _optionalChain([tenantConfig, 'optionalAccess', _8 => _8.get, 'call', _9 => _9("forceHost")]) !== _optionalChain([window, 'optionalAccess', _10 => _10.location, 'optionalAccess', _11 => _11.hostname])
      ) {
        window.location.href = `https://${_optionalChain([tenantConfig, 'optionalAccess', _12 => _12.get, 'call', _13 => _13("forceHost")])}${
          _optionalChain([window, 'optionalAccess', _14 => _14.location, 'optionalAccess', _15 => _15.pathname])
        }`;

        return;
      }

      const forceLanguage = _optionalChain([tenantConfig, 'optionalAccess', _16 => _16.get, 'call', _17 => _17("forceLanguage"), 'optionalAccess', _18 => _18.id]);
      const currentLanguageId = forceLanguage || getCurrentLanguageSync();

      if (forceLanguage && forceLanguage !== getCurrentLanguageSync()) {
        changeLanguage(forceLanguage);
      }

      const defaultTenant = new Parse.Object("OD3_Tenant", {
        id: DEFAULT_TENANT_ID,
      });

      console.log("current language", currentLanguageId);

      await translate("app:menu.frontpage");

      const frontpageImage = await preloadImage(
        "frontpage-image",
        _optionalChain([tenantConfig, 'optionalAccess', _19 => _19.get, 'call', _20 => _20("frontpageBackgroundImage"), 'optionalAccess', _21 => _21.url, 'call', _22 => _22()])
      );

      const currentLanguage = await new Parse.Query(Language).get(
        currentLanguageId
      );

      document.title = _optionalChain([tenantConfig, 'optionalAccess', _23 => _23.get, 'call', _24 => _24("title")]) || "ASEW Rechnungserklärer";

      const primaryColor = _optionalChain([tenantConfig, 'optionalAccess', _25 => _25.get, 'call', _26 => _26("primaryColor")]) || "#ff0000";
      const secondaryColor = _optionalChain([tenantConfig, 'optionalAccess', _27 => _27.get, 'call', _28 => _28("secondaryColor")]) || "#ff0000";
      const primaryTextColor =
        _optionalChain([tenantConfig, 'optionalAccess', _29 => _29.get, 'call', _30 => _30("primaryTextColor")]) || "#ff0000";
      const secondaryTextColor =
        _optionalChain([tenantConfig, 'optionalAccess', _31 => _31.get, 'call', _32 => _32("secondaryTextColor")]) || "#ff0000";

      let root = document.documentElement;

      root.style.setProperty("--primaryColor", primaryColor);
      root.style.setProperty("--primaryTextColor", primaryTextColor);
      root.style.setProperty("--primaryColor-alpha", hexToRgbA(primaryColor));
      root.style.setProperty("--secondaryColor", secondaryColor);
      root.style.setProperty("--secondaryTextColor", secondaryTextColor);
      root.style.setProperty(
        "--secondaryColor-alpha",
        hexToRgbA(secondaryColor)
      );

      const invoices = await new Parse.Query(Invoice)
        .equalTo("tenant", tenant)
        .descending("order")
        .find();

      const invoicePages = await new Parse.Query(InvoicePage)
        .equalTo("tenant", tenant)
        .descending("order")
        .find();

      const defaultGlossaryEntries = await new Parse.Query(GlossaryEntry)
        .equalTo("tenant", defaultTenant)
        .equalTo("language", currentLanguage)
        .ascending("title")
        .limit(999999999999)
        .find();

      const customGlossaryEntries = await new Parse.Query(GlossaryEntry)
        .equalTo("tenant", tenant)
        .equalTo("language", currentLanguage)
        .ascending("title")
        .limit(999999999999)
        .find();

      const allGlossaryEntries = [
        ...defaultGlossaryEntries,
        ...customGlossaryEntries,
      ];

      const glossaryEntriesMapByKeyword = {};

      for (const e of allGlossaryEntries) {
        glossaryEntriesMapByKeyword[e.get("keyword")] = e;
      }

      const glossaryEntries = Object.values(glossaryEntriesMapByKeyword);

      glossaryEntries.sort(function (a, b) {
        const keyA = a.get("title").toUpperCase();
        const keyB = b.get("title").toUpperCase();
        // Compare the 2 dates

        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      const glossaryEntriesMapById = {};
      const gloassryEntriesMapByTag = {};

      for (const entry of glossaryEntries) {
        glossaryEntriesMapById[entry.id] = entry;

        gloassryEntriesMapByTag[entry.get("title")] = entry;

        for (const tag of entry.get("tags") ) {
          gloassryEntriesMapByTag[tag] = entry;
        }
      }


      console.log("TEST",_optionalChain([tenantConfig, 'optionalAccess', _33 => _33.get, 'call', _34 => _34("hideVideoIds")]));
      let videos = await new Parse.Query(Video)
        .equalTo("tenant", tenant)
        // .equalTo("language", currentLanguage)
        .descending("order")
        .find();

      if (tenantId !== DEFAULT_TENANT_ID) {
        const defaultVideos = await new Parse.Query(Video)
          .equalTo("tenant", defaultTenant)
          // .equalTo("language", currentLanguage)
          .descending("order")
          .find();

        videos = [...videos, ...defaultVideos].sort(
          (a, b) => b.get("order") - a.get("order")
        );
      }
      videos = videos.filter(video => !_optionalChain([tenantConfig, 'optionalAccess', _35 => _35.hideVideoIds, 'access', _36 => _36.includes, 'call', _37 => _37(video.id)]));




      if (tenantId === "JgpCYeVWXc") {
        videos = videos.filter((v) => v.id !== "KTQB3RolhA");
        videos = videos.filter((v) => v.id !== "ZuEm0R5DQP");
        videos = videos.filter((v) => v.id !== "GeGrDaCY97");
      }

      if (tenantId === "aGZR6AgRdt") {
        videos = videos.filter((v) => v.id !== "PDRzhtk8FB");
      }

      if (tenantId === "gywDzRgrKk") {
        videos = videos.filter((v) => v.id !== "J5oc9s1BA9");
      }

      if (tenantId === "aoBQ6s98Kl") {
        videos = videos.filter((v) => v.id !== "J5oc9s1BA9");
        videos = videos.filter((v) => v.id !== "PmMkUiNmtL");
        videos = videos.filter((v) => v.id !== "vMBmhUZYk1");
        videos = videos.filter((v) => v.id !== "lZggfAg8OC");
        videos = videos.filter((v) => v.id !== "ZuEm0R5DQP");
        videos = videos.filter((v) => v.id !== "7iCB2AEMgH");
        videos = videos.filter((v) => v.id !== "IBGFIfCkCP");
      }

      this.store.update((draft) => {
        draft.tenantId = tenantId;
        draft.tenantConfig = tenantConfig;
        draft.invoices = invoices;
        draft.invoicePages = invoicePages;
        draft.glossaryEntries = glossaryEntries;
        draft.glossaryEntriesMapById = glossaryEntriesMapById;
        draft.glossaryEntriesMapByKeyword = glossaryEntriesMapByKeyword;
        draft.gloassryEntriesMapByTag = gloassryEntriesMapByTag;
        draft.videos = videos;
        draft.frontpageImage = frontpageImage;
        draft.forceLanguage = forceLanguage;
      });

      this.setLoading(false);

      console.log("init Rechnungserklärer");
      console.log("tenant.id =", tenantId);
      console.log("tenantConfig.id =", tenantConfig.id);
      console.log("parentTenant.id =", DEFAULT_TENANT_ID);
    } catch (error) {
      console.error(error);

      this.error = true;
      this.setLoading(false);
    } finally {
      window.localStorage.removeItem("Parse/asew/installationId");
    }
  }
}

export const app = new AppService();

const defaultSelector = (state) => state;



export function useAppState(selector) {
  if (selector) {
    return useServiceStore(app, selector);
  } else {
    return useServiceStore(app, defaultSelector) ;
  }
}

export function getTitle(title) {
  if (getCurrentLanguageSync() !== "de") {
    switch (title) {
      case "Strom":
      case "Stromrechnung":
      case "Ökostrom":
      case "Ökostromrechnung":
        return translateSync("app:invoice.electricity");
      case "Gas":
      case "Erdgas":
      case "Gasrechnung":
      case "Erdgasrechnung":
      case "Ökogas":
      case "Ökogasrechnung":
        return translateSync("app:invoice.gas");
      case "Wasser":
      case "Wasserrechnung":
      case "Trinkwasser":
        return translateSync("app:invoice.water");
      case "Fernwärme":
      case "Fernwärmerechnung":
        return translateSync("app:invoice.remoteheating");
      case "Fernwärme (Kamen)":
        return translateSync("app:invoice.remoteheatingkamen");
      case "Fernwärme (Bergkamen)":
        return translateSync("app:invoice.remoteheatingbergkamen");
      case "Gesamt":
      case "Gesamtrechnung":
      case "Energie":
      case "Energierechnung":
      case "Jahresrechnung":
      case "Strom, Gas, Wasser":
      case "Strom, Gas & Wasser":
      case "Energie- und Wasserrechnung":
        return translateSync("app:invoice.energy");
      case "Gesamt":
      case "Gesamtrechnung":
      case "Energie":
      case "Energierechnung":
        return translateSync("app:invoice.energy");
      case "Übersicht":
        return translateSync("app:invoice.overview");
      case "Strom- und Gaspreisbremse":
        return translateSync("app:invoice.pricecap");
      case "Strom (Eintarif)":
      case "Strom (ET)":
      case "Stromrechnung (Eintarif)":
      case "Stromrechnung (ET)":
        return translateSync("app:invoice.electricitysingle");
      case "Strom (Zweitarif)":
      case "Strom (ZT)":
      case "Strom (HT/NT)":
      case "Stromrechnung (Zweitarif)":
      case "Stromrechnung (ZT)":
      case "Stromrechnung (HT/NT)":
        return translateSync("app:invoice.electricitydual");
      case "Abwasser":
      case "Abwasserrechnung":
        return translateSync("app:invoice.wastewater");
    }
  }

  return title || "";
}

export function getInvoiceTitle(invoice) {
  const title = invoice.get("title");

  return getTitle(title);
}

function hexToRgbA(hex) {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c = hex.substring(1).split("");

    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }

    const r = parseInt(c[0] + [1], 16);
    const g = parseInt(c[2] + [3], 16);
    const b = parseInt(c[4] + [5], 16);

    return "rgba(" + [r, g, b].join(",") + ",0.75)";
  }

  return hex;
}

async function preloadImage(key, src) {
  if (!src) {
    return undefined;
  }

  const response = await fetch(src);

  const blob = await response.blob();

  return URL.createObjectURL(blob);
}
